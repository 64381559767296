import React, { useState } from 'react'
import { Layout } from '@components/organisms'
import { PageHeader } from '@components/molecules'
import { Tabs } from '@components/atoms'
import EDITIONS from '@constants/editions'
import { Gallery } from '@pagesComponents/winningPhotos'
import WINNING_PHOTOS from '@constants/winningPhotos'

const WinningPhotos = () => {
    const [edition, setEdition] = useState(0)
    const editionsArr = Object.values(EDITIONS).filter(el => WINNING_PHOTOS[el.id]).reverse()

    return (
        <Layout seo={{ title: 'Fotos Premiadas', description: 'Lista de fotos premiadas nas edições anteriores do Concurso de Fotografia - Foco no Cooperativismo'}}> 
            <PageHeader icon={{ name: 'trophy', alt: 'Icone Taça de vencedor' }} description='As obras com a melhor composição, técnica, originalidade, criatividade, emoção,
                sentimento, adequação ao tema e categoria'
            >
                Fotos premiadas
            </PageHeader>
            <Tabs selected={ edition } onChange={ key => setEdition(key) } tabs={ editionsArr.map(el => ({ caption: el.id, label: `${ el.index }ª edição` })) }/>
            <Gallery edition={ editionsArr[edition].id }/>
        </Layout>
    )
}

export default WinningPhotos