const styles = {
    background: {
        marginTop: 4
    },
    divider: {
        width: '10%',
        height: 2,
        backgroundColor: 'primary.main'
    },
    photo: {
        position: 'relative',
        cursor: 'pointer'
    },
    gradient: {
        position: 'absolute',
        width: '100%',
        height: '50%',
        left: 0,
        bottom: 0,
        transition: 'opacity 1s',
        background: `linear-gradient(transparent, rgba(0,0,0,0.5))`
    },
    category: {
        display: 'flex',
        pl: {
            md: 6,
            xs: 0
        },
        pb: {
            md: 0,
            xs: 3
        },
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100%',
        backgroundColor: {
            md: '#fafafa',
            xs: '#fff'
        }
    },
    categoryDesc: {
        fontSize: {
            md: 'h4.fontSize',
            xs: 28
        },
        fontWeight: 'bold'
    },
    modal: {
        width: '100%',
        backgroundColor: 'rgba(0, 0, 0, .2)',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    closeButton: {
        position: 'absolute',
        top: 20,
        right: 20,
        color: '#fff'
    }
}

export default styles