/**List of winning photos title, author and position, of each edition and category */
const WINNING_PHOTOS = {
    '2021': {
        professional: [
            {
                title: 'Cultivando o futuro!',
                author: 'Camila Soares Narvaez',
                position: 1
            },
            {
                title: 'Holoceno preservado',
                author: 'Felipe José Ferreira',
                position: 2
            },
            {
                title: 'Garcinha',
                author: 'Maurílio Quadros da Rosa',
                position: 3
            }
        ],
        associate: [
            {
                title: 'O Reflexo Da Minha Terra',
                author: 'Wagner Dondossola Brovedan',
                position: 1
            },
            {
                title: 'Sou filho da roça',
                author: 'Davi Oliveira prudencio',
                position: 2
            },
            {
                title: 'Pescadores arrumando a rede de cerco',
                author: 'Mariele Apolinário Daitx',
                position: 3
            }
        ],
        unassociated: [
            {
                title: 'Esse é o meu trabalho',
                author: 'Monise Possamai da Silva',
                position: 1
            },
            {
                title: 'Simplicidade que gera felicidade!',
                author: 'Marizete Souza de Matos',
                position: 2
            },
            {
                title: 'O zelo na arte de cuidar',
                author: 'Anajara Ribeiro de Souza',
                position: 3
            }
        ]
    },
    '2020': {
        professional: [
            {
                title: 'Amigo dos rios',
                author: 'Michell Melo Bezerra e Silva',
                position: 1
            },
            {
                title: 'Cultivando amor',
                author: 'Morgana Pierosan',
                position: 2
            },
            {
                title: 'Temporada de pesca em Balneário Gaivota',
                author: 'David Cardoso',
                position: 3
            }
        ],
        associate: [
            {
                title: 'Nossas tradições, meu orgulho',
                author: 'Mariele Apolinário Daitx',
                position: 1
            },
            {
                title: 'Riquezas da roça',
                author: 'Everton Silveira Machado',
                position: 2
            },
            {
                title: 'Equilíbrio e harmonia nas relações',
                author: 'José Luiz Ronconi',
                position: 3
            }
        ],
        unassociated: [
            {
                title: 'O trabalho de todos os dias',
                author: 'Volnei Vargas',
                position: 1
            },
            {
                title: 'A sustentabilidade atravessando gerações',
                author: 'Sofia Buttelli Carlomagno',
                position: 2
            },
            {
                title: 'Pescando no paraíso',
                author: 'Pablo Alfredo De Luca',
                position: 3
            }
        ]
    },
    '2019': {
        professional: [
            {
                title: 'De vó para neta',
                author: 'Taís Rosa Abel',
                position: 1
            },
            {
                title: 'Sustentabilidade de amor',
                author: 'Graziele da Silva Martins',
                position: 2
            },
            {
                title: 'A horta do vovô',
                author: 'Darline Minatto',
                position: 3
            }
        ],
        associate: [
            {
                title: 'No velho carro de boi',
                author: 'Juliane Pagnan Bristot',
                position: 1
            },
            {
                title: 'A força do homem do campo',
                author: 'Everton Silveira Machado',
                position: 2
            },
            {
                title: 'Bom dia Arroio, lugar de gente feliz',
                author: 'Alcir Manoel Marcos',
                position: 3
            }
        ],
        unassociated: [
            {
                title: 'Parceria cedo no dia',
                author: 'Cibele Souto Amade',
                position: 1
            },
            {
                title: 'A força do trabalho',
                author: 'Volnei Vargas',
                position: 2
            },
            {
                title: 'Tradição de irmão para irmão',
                author: 'Tulio Rocha Alves',
                position: 3
            }
        ]
    },
    '2018': {
        professional: [
            {
                title: 'Amor de interior',
                author: 'Aline Crepaldi',
                position: 1
            },
            {
                title: 'Plantar, sonhar e agradecer',
                author: 'Darline Minatto',
                position: 2
            },
            {
                title: 'Alvorada',
                author: 'Flávio Filho',
                position: 3
            }
        ],
        associate: [
            {
                title: 'Arroio Meu Grande Amor ',
                author: 'Alcir Marcos',
                position: 1
            },
            {
                title: 'Torres, minha origem',
                author: 'Antoni Godinho',
                position: 2
            },
            {
                title: 'Seguindo o mesmo caminho',
                author: 'Gabriel Longaretti',
                position: 3
            }
        ],
        unassociated: [
            {
                title: 'Vida no campo',
                author: 'Volnei Vargas',
                position: 1
            },
            {
                title: 'Cais de pesca',
                author: 'Eduardo Costa',
                position: 2
            },
            {
                title: 'A natureza não faz milagres, faz revelações',
                author: 'Karine Marquina',
                position: 3
            }
        ]
    }
}

export default WINNING_PHOTOS