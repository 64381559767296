import React, { useState } from 'react'
import EDITIONS from '@constants/editions'
import WINNING_PHOTOS from '@constants/winningPhotos'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import useFolderImages from '@hooks/useFolderImages'
import Container from '@mui/material/Container'
import styles from './styles'
import Grid from '@mui/material/Grid'
import Modal from '@mui/material/Modal'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/CloseRounded'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from "@mui/material/styles"
import Photo from './Photo'

const Gallery = props => {
    const { edition } = props
    const photos = useFolderImages(`winning${ edition }`)
    const [fullscreen, setFullscreen] = useState(null)
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    const handleChangeFullScreen = image => {
        setFullscreen(image)
    }

    return (
        <Container maxWidth='lg' sx={ styles.background }>
            { Object.values(EDITIONS[edition].categories).map(category => (
                <Grid container spacing={ 1 } rowSpacing={ 1 } key={ category.id } style={{ marginBottom: 50 }}>
                    <Grid item xs={ 12 } md={ 6 }>
                        <Box sx={ styles.category }>
                            <Typography variant='h6' color='secondary'>{ category.name }</Typography>
                            <Typography variant='h4' sx={ styles.categoryDesc } gutterBottom>{ category.description }</Typography>
                            <Box sx={ styles.divider }/>
                        </Box>
                    </Grid>
                    { WINNING_PHOTOS[edition][category.id].map(photo => (
                        <Photo { ...photo } key={ `${ category.id }_${ photo.position }` } onClick={ handleChangeFullScreen }
                            image={ photos.find(({ name }) => name === `${ category.id }_${ photo.position }`) } infosVisible={ isMobile }
                        />
                    ))}
                </Grid>
            ))}
            <Modal onClose={ () => handleChangeFullScreen(null) } open={ Boolean(fullscreen) } maxWidth='xl'>
                <Box sx={ styles.modal }>
                    { fullscreen && <GatsbyImage image={ getImage(fullscreen) } alt='Foto' objectFit='contain' style={{ width: isMobile ? '95%' : '80%' }}/> }
                    <IconButton size='large' onClick={ () => handleChangeFullScreen(null) } sx={ styles.closeButton }>
                        <CloseIcon/>
                    </IconButton>
                </Box>
            </Modal>
        </Container>
    )
}

export default Gallery