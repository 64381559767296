import React, { useState } from 'react'
import Box from '@mui/material/Box'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { PhotoTitle } from '@components/molecules'
import styles from './styles'
import Grid from '@mui/material/Grid'

const Photo = props => {
    const { image, onClick, infosVisible } = props
    const [hover, setHover] = useState(false)

    return (
        <Grid item xs={ 12 } md={ 6 }>
            <Box sx={ styles.photo } onMouseOver={ () => setHover(true) } onMouseOut={ () => setHover(false) } onClick={ () => onClick(image) }>
                <GatsbyImage alt='Foto premiada' image={ getImage(image) } style={{ height: '100%' }}/>
                <div style={{ ...styles.gradient, opacity: hover ? 1 : 0 }}/>
                <PhotoTitle small { ...props } visible={ hover || infosVisible }/>
            </Box>
        </Grid>
    )
}

export default Photo